<!--/**-->
<!--* 自动生成 vue列表代码【模块名：角色申请】-->
<!--* 生成日期：2024/04/12 19:03:33-->
<!--* 生成路径: src/pages/role_apply_record/RoleApplyRecordList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='角色名称'>
              <el-input v-model='searchCondition.role_names' placeholder='角色名称' clearable></el-input>
            </el-form-item>
            <el-form-item label='处理状态'>
              <el-input v-model='searchCondition.status' placeholder='处理状态' clearable></el-input>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <!--          <el-col :span='1.5'>-->
          <!--            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>-->
          <!--          </el-col>-->
          <!--          <el-col :span='1.5'>-->
          <!--            <el-button icon='el-icon-upload' @click='openImport=true'>导入</el-button>-->
          <!--          </el-col>-->
          <el-col :span='1.5'>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("role_apply_record_export")'
                        @export='handleExport'></export-btn>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-view' @click='handleEdit(row)'>详情</el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

    </PageHeaderLayout>

    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='800px' size='small' center append-to-body
               :close-on-click-modal='false'>
      <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
        <el-form-item label='申请人ID' prop='apply_user_id'>
          <el-input v-model='form.apply_user_name' placeholder='请输入申请人ID' disabled clearable />
        </el-form-item>
        <el-form-item label='接收人ID' prop='rec_user_id'>
          <el-input v-model='form.rec_user_name' placeholder='请输入接收人ID' disabled clearable />
        </el-form-item>

        <el-form-item label='角色名称' prop='role_names'>
          <el-input v-model='form.role_names' placeholder='请输入角色名称' disabled clearable />
        </el-form-item>
        <el-form-item label='处理状态' prop='status'>
          <el-input v-model='form._status' placeholder='请输入处理状态' disabled clearable />
        </el-form-item>
        <el-form-item label='拒绝原因'>
          <el-input v-model='form.rej_reason' placeholder='请输入拒绝原因' clearable />
        </el-form-item>
        <el-form-item label='备注'>
          <el-input v-model='form.remark' placeholder='请输入备注' clearable />
        </el-form-item>

      </el-form>
      <div slot='footer' class='dialog-footer' v-if='form.status==1'>
        <!--        <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定-->
        <!--        </el-button>-->
        <!--        <el-button @click='cancel'>取 消</el-button>-->
        <el-button type='primary' @click='approvalApply(2)' :disabled='loadingCommit' :loading='loadingCommit'>通过
        </el-button>
        <el-button type='warning' @click='approvalApply(3)' :disabled='loadingCommit' :loading='loadingCommit'>拒绝
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'RoleApplyRecordList',
  components: { ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'role_apply_record',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        apply_user_id: null,
        rec_user_id: null,
        role_ids: null,
        role_names: null,
        status: null,
        rej_reason: null,
        remark: null
      },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: true, prop: 'id', label: '序号', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'apply_user_name', label: '申请人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'rec_user_name', label: '接收人', width: 120, sortable: false, fixed: false },
        // { hide: false, prop: 'role_ids', label: '角色ID集合', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'role_names', label: '角色名称', width: 160, sortable: false, fixed: false },
        { hide: false, prop: '_status', label: '处理状态', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'rej_reason', label: '拒绝原因', width: 220, sortable: false, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 220, sortable: false, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false }
        // { hide: false, prop: 'deleted_at', label: '删除时间', width: 120, sortable: false, fixed: false }
      ],
      // 弹出层标题
      title: '添加/修改-角色申请',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        apply_user_id: [{ required: true, message: '申请人ID不能为空', trigger: 'blur' }],
        rec_user_id: [{ required: true, message: '接收人ID不能为空', trigger: 'blur' }],
        role_ids: [{ required: true, message: '角色ID集合不能为空', trigger: 'blur' }],
        role_names: [{ required: true, message: '角色名称不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '处理状态不能为空', trigger: 'blur' }],
        rej_reason: [{ required: true, message: '拒绝原因不能为空', trigger: 'blur' }],
        remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:role_apply_record'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getRoleApplyRecordList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteRoleApplyRecord(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-角色申请'
      // let { info } = await this.$api.getRoleApplyRecordInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-角色申请'
      // let { info } = await this.$api.getRoleApplyRecordInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveRoleApplyRecord(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },

    //审批
    async approvalApply(status) {

      //审批通过 或拒绝
      let postdata = {
        id: this.form.id,
        status: status
      }
      if (postdata.status == 3) {
        postdata['rej_reason'] = this.form.rej_reason
        if (!postdata['rej_reason']) {
          this.$notify.warning('拒绝原因必填！！！')
          return false
        }
      }

      this.loadingCommit = true
      // 【发起请求】
      // this.$notify.success(JSON.stringify(postdata))
      await this.$api.approvalRoleApply(postdata)
      this.form.status = status
      await this.getList()
      this.open = false
      this.loadingCommit = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportRoleApplyRecords(searchCondition)
        let name = `角色申请`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
